import cns from "classnames";
import { Skeleton, Steps, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

import { Nil } from "@/types";
import { RegistrationStatus } from "@/constants";
import { formatDateTime } from "@/features/registrationTab/utils";
import { humanizeRegistrationStatus } from "@/utils";

import { DigitalCarReg, HistoryItem } from "../../types";

import cn from "./styles.less";

enum StepStatus {
  Pending = "PENDING",
  Success = "SUCCESS",
  Failed = "FAILED",
}

interface StatusOverviewProps {
  data: Nil | DigitalCarReg;
  documentsSignedAt: Nil | string;
}

const STEPS_IN_ORDER: RegistrationStatus[] = [
  RegistrationStatus.PendingOnRegistration,
  RegistrationStatus.PendingOnCustomerData,
  RegistrationStatus.PendingCustomerSignature,
  RegistrationStatus.SubmitRequestToKba,
  RegistrationStatus.Approved,
  RegistrationStatus.PrintLicensePlates,
  RegistrationStatus.Completed,
];

const findLastHistoryItem = (step: string, history: HistoryItem[]) =>
  history.reduce<undefined | { historyIndex: number; data: HistoryItem }>(
    (last, data, historyIndex) =>
      data.step === step ? { historyIndex, data } : last,
    undefined
  );

const getSteps = (history: HistoryItem[]) =>
  STEPS_IN_ORDER.map((step) => ({
    step,
    ...findLastHistoryItem(step, history),
  })).map(({ step, historyIndex, data }, index, all) => {
    const isFailed = data?.status === StepStatus.Failed;
    const priorSteps = all.slice(0, index);
    // A step becomes irrelevant/stale if some of the prior steps appear
    // after it in history meaning that the flow has been restarted
    const isStepRelevant = priorSteps.every(
      (prior) => (historyIndex ?? 0) > (prior.historyIndex ?? 0)
    );

    return {
      step,
      failedOnPrevRun: isFailed && !isStepRelevant,
      data: isFailed || isStepRelevant ? data : undefined,
    };
  });

export const DigitalRegistrationStatusOverview = ({
  data,
  documentsSignedAt,
}: StatusOverviewProps) => {
  if (!data) {
    return <Skeleton />;
  }

  return (
    <Steps direction="vertical" className={cn.steps}>
      {getSteps(data.history ?? []).map(({ step, failedOnPrevRun, data }) => {
        const commonProps = {
          key: step,
          title: (
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {humanizeRegistrationStatus(step)}
            </Typography.Text>
          ),
          subTitle:
            data?.date || failedOnPrevRun ? (
              <>
                {failedOnPrevRun && "(Previous try)"}
                {data?.date && (
                  <span data-qa-selector="status-date">
                    &nbsp;{formatDateTime(data.date)}
                  </span>
                )}
              </>
            ) : null,
          "data-qa-id": `step-${step}`,
        };

        if (!data) {
          return <Steps.Step {...commonProps} />;
        }

        switch (data.status) {
          case StepStatus.Pending:
            return (
              <Steps.Step
                {...commonProps}
                className={cn.pending}
                status="wait"
                icon={<ClockCircleOutlined style={{ fontSize: "32px" }} />}
              />
            );

          case StepStatus.Success:
            return (
              <Steps.Step
                {...commonProps}
                status="finish"
                {...(step === RegistrationStatus.PendingCustomerSignature &&
                  documentsSignedAt && {
                    description: `Documents signed at: ${
                      formatDateTime(documentsSignedAt) ?? "-"
                    }`,
                  })}
              />
            );

          case StepStatus.Failed:
            return (
              <Steps.Step
                {...commonProps}
                className={cns({ [cn.grayError]: failedOnPrevRun })}
                status="error"
                description={data.error?.description ?? "Unknown error"}
              />
            );

          default:
            return <Steps.Step {...commonProps} />;
        }
      })}
    </Steps>
  );
};
