import type {
  DigitalCarRegFragment,
  FindCarRegistrationDataQuery,
} from "@/generated/graphql";
import type { ItemFromNullableList, Replace } from "@/types";
import type { RegistrationStatus } from "@/constants";

export enum LicensePlateType {
  Standard = "STANDARD",
  Electric = "ELECTRIC",
}

export type OrderDetails = NonNullable<
  FindCarRegistrationDataQuery["orderDetails"]
>;

export type RegAction = ItemFromNullableList<
  FindCarRegistrationDataQuery["regActions"]
>;

type AssigneeProjection = NonNullable<DigitalCarRegFragment["assignedToUser"]>;

export interface Assignee extends AssigneeProjection {
  id: string;
}

export interface LicensePlate
  extends Replace<
    NonNullable<DigitalCarRegFragment["licensePlate"]>,
    "licensePlateType",
    LicensePlateType | null
  > {}

export interface DigitalCarReg
  extends Replace<
    DigitalCarRegFragment,
    "licensePlate" | "oldLicensePlate",
    LicensePlate | null
  > {
  id: string;
  status: RegistrationStatus;
}

export type HistoryItem = NonNullable<DigitalCarReg["history"]>[number];

export type Address = NonNullable<DigitalCarReg["address"]>;

export type BankAccount = NonNullable<DigitalCarReg["bankAccount"]>;

export type BankAccountDetails = NonNullable<BankAccount["details"]>;

export interface DigitalCarRegEditableDetails
  extends Pick<
    DigitalCarReg,
    | "licensePlate"
    | "licensePlatePin"
    | "oldLicensePlate"
    | "registrationCertificate2Number"
    | "registrationCertificate2NumberSecurityCode"
    | "registrationCertificate1Code"
    | "registrationCertificate1CodeSecurityCode"
    | "evbNumber"
    | "holderPlaceOfBirth"
    | "vehicleTaxesCollectedPeriod"
  > {
  address: null | Pick<Address, "city" | "street" | "zipcode" | "houseNumber">;
  bankAccount: null | {
    details: null | Pick<
      BankAccountDetails,
      "bic" | "iban" | "bankName" | "holder"
    >;
  };
}
